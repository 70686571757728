<template>
	<div class="justify-self-end ml-auto">
		<router-link
      :to="{ name: 'search' }"
      class="flex items-center gap-x-1.5 px-3 py-2 text-white bg-transparent rounded-md cursor-pointer transition-colors hover:text-gray-600 hover:bg-white"
    >
      <font-awesome-icon icon="fa-solid fa-magnifying-glass" /> Search
    </router-link>
	</div>
</template>
