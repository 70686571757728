<template>
	<div>
		<h1 v-if="article.title" class="text-3xl mb-4" v-html="article.title"></h1>
		<h2 v-if="article.subtitle" class="text-2xl mb-4" v-html="article.subtitle"></h2>
		<div
      v-if="article.content"
      class="prose-base prose-a:text-ch-red prose-ul:list-disc prose-ol:list-decimal prose-table:block prose-table:w-full prose-table:overflow-x-auto prose-th:border prose-th:p-2 prose-td:border prose-td:p-2"
      v-html="article.content"
    ></div>
	</div>
</template>

<script>
export default {
	props: ['article']
}
</script>
