<template>
	<div class="relative group">
		<router-link
      :to="to"
      class="block px-3 py-2 text-white bg-transparent rounded-md cursor-pointer transition-colors hover:text-gray-600 hover:bg-white group-hover:text-gray-600 group-hover:bg-white"
      :class="{'underline underline-offset-8 decoration-2': menuIsActive}"
    >
      <slot v-if="$slots.default"></slot>
      <template v-else>{{ name }}</template>
		</router-link>

		<div
      v-if="childrenMenu?.length > 0"
      class="absolute invisible group-hover:visible -top-2 left-full z-10 w-max p-2 bg-gray-600 rounded-md shadow-lg"
    >
			<component
        v-for="child of childrenMenu"
        :key="child.name"
        :is="child.type"
        :name="child.name"
        :to="child.href"
        class="z-10"
      />
		</div>
	</div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import MenuItem from './MenuItem.vue';
import ExternalMenuItem from './ExternalMenuItem.vue';

export default {
	components: { MenuItem, ExternalMenuItem },
	props: {
		name: {
			type: String,
			required: true
		},
		to: {
			type: String,
			required: true
		},
		childrenMenu: {
			type: Array,
			required: true
		}
	},
	computed: {
		menuIsActive() {
			return this.to !== '/' && this.$route.fullPath.includes(this.to)
		}
	}
}
</script>
