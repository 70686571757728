<template>
	<a
    class="relative flex items-center gap-x-1.5 px-3 py-2 rounded-md cursor-pointer transition-colors"
    :class="[
      menuOpen ? 'text-gray-600 bg-white' : 'text-white bg-transparent hover:text-gray-600 hover:bg-white',
      menuIsActive && 'underline underline-offset-8 decoration-2'
    ]"
		@click.prevent="toggle"
		v-click-outside-element="close"
  >
		{{ name }} <font-awesome-icon class="text-[0.6rem] rotate-90" icon="fa-solid fa-play" />

		<div
      class="absolute top-14 left-0 z-10 w-max p-2 bg-gray-600 rounded-md shadow-lg dropdown-menu"
			:class="menuOpen ? '' : 'hidden'"
    >
			<component
          v-for="child of childrenMenu"
          :key="child.name"
          :is="child.type"
          :name="child.name"
          :to="child.href"
          :children-menu="child.children"
          class="z-10"
      />
		</div>
	</a>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import MenuItem from './MenuItem.vue';
import RecursiveDropdownMenu from './RecursiveDropdownMenu.vue';

export default {
	components: { MenuItem, RecursiveDropdownMenu },
	props: {
		name: {
			type: String,
			required: true
		},
		childrenMenu: {
			type: Array,
			required: true
		},
		to: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			menuOpen: false
		}
	},
	methods: {
		toggle() {
			this.menuOpen = !this.menuOpen
		},
		close() {
			this.menuOpen = false
		}
	},
  computed: {
    menuIsActive() {
      return this.to !== '/' && this.$route.fullPath.includes(this.to)
    }
  }
}
</script>

<style scoped>
.dropdown-menu::before {
	content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='2.1em' fill='rgb(75 85 99)' viewBox='0 0 384 512'%3E%3Cpath d='M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z'/%3E%3C/svg%3E");
	position: absolute;
	top: -1.25rem;
	left: 1rem;
  font-size: 2rem;
	transform: rotate(-90deg);
	z-index: -1;
}
</style>
