<template>
	<basic-skeleton-loader v-if="!articles" />

	<base-container v-else-if="typeof articles === 'object' && articles.length === 0">
		<p>No content was found for this page</p>
	</base-container>

	<template v-else>
		<base-container class="flex flex-wrap items-center justify-between py-4 !mb-4 gap-y-2 md:gap-y-0" v-if="page?.meta?.issue">
			<div>
				<h1 class="mb-0 text-xl font-semibold text-gray-700">{{ page.meta.issue }}</h1>
				<h2 class="mt-0 text-gray-600 text-md">{{ page.meta?.subtitle }}</h2>
			</div>
			<a  v-if="page?.meta?.table_of_contents" :href="page.meta.table_of_contents" target="_blank" class="rounded-md transition-all bg-gray-500 hover:bg-gray-700 text-white font-medium py-1.5 px-5 text-sm w-fit self-start">Table of Contents</a>
		</base-container>

		<base-container v-for="article in articles" :key="article.id" class="!mb-4">
			<component :is="contentType(article.type)" :article="article" />
		</base-container>
	</template>
</template>

<script>
export default {
	inject: ['api'],
	props: {
		page: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			articles: null
		}
	},
	methods: {
		contentType(type) {
			return this.$store.state.contentTypes[type]
		}
	},
	created() {
    const currentPath = this.$route.fullPath

    // if path matches the agreed-upon pattern to get all articles containing a live video reference for the provided year, i.e. /ch-live/2025-live-contents
    if (/^\/ch-live\/\d{4}-live-contents$/.test(currentPath)) {
      // extract year from the path
      const year = currentPath.match(/\d{4}/g)?.[0] ?? null

      // call api endpoint that gets all articles containing a live video reference for the provided year
      this.api({
        url: `/content/live/${year}`,
        callback: data => {
          this.articles = data
        },
        errorCallback: () => {
          this.articles = []
          console.error('Error when requesting content')
        }
      })
    } else { // default content handling
      this.api({
        url: `/content/${this.page.id}`,
        callback: data => {
          this.articles = data
        },
        errorCallback: () => {
          this.articles = []
          console.error('Error when requesting content')
        }
      })
    }
	}
}
</script>
