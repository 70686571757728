<template>
	<div class="container flex flex-col items-center mx-auto">
		<page-not-found v-if="notFound"></page-not-found>
		<basic-skeleton-loader v-else-if="!page" />
		<template v-else>
			<component :is="displayComponent" :page="page" />
		</template>
	</div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import PageNotFound from '@/pages/PageNotFound.vue'
import BlogComponent from '@/pages/BlogComponent.vue'
import PostComponent from '@/pages/PostComponent.vue'

import { updateTitle } from '@/composables/update-title'
import { setCanonical } from '@/composables/set-canonical'

export default {
	components: { PageNotFound, BlogComponent, PostComponent },
	inject: ['api'],
	data() {
		return {
			page: null,
			articles: null,
			notFound: false
		}
	},
	computed: {
		displayComponent() {
			return this.page.top_level ? 
				this.$store.state.pageTypes['post'] :
				this.$store.state.pageTypes['blog']
		}
	},
	created() {
		const currentPath = this.$route.fullPath

    this.api({
      url: `/page${currentPath}`,
      callback: data => {
        if (data.top_level === undefined || data.type === undefined || !this.$store.state.pageTypes[data.type]) {
          console.error('Unknown page type: ' + data.type)
          this.notFound = true
        } else {
          this.page = data
        }
      },
      errorCallback: () => {
        this.notFound = true
      }
    })
	},
	watch: {
		'page'() {
			// set the title of the page
			updateTitle(this.page.title)
		}
	},
	beforeRouteUpdate(to, from, next) {
		setCanonical(to)

		next()
	},
	methods: {
		showLightbox(event) {
			if (event.target.tagName !== 'IMG' || event.target.classList.contains('graphical-abstract')) {
				return
			}

			const articleElement = event.target.closest('article')
			if (!articleElement) {
				return
			}

			this.$store.commit('setLightboxImages', event.target.src)
			this.$store.commit('toggleLightbox', true)
		}
	},
	mounted() {
		document.addEventListener('click', this.showLightbox)
	},
	beforeUnmount() {
		document.removeEventListener('click', this.showLightbox)
	},
}
</script>
