<template>
  <a
    :href="to"
    target="_blank"
    class="flex items-center gap-x-1.5 px-3 py-2 text-white bg-transparent rounded-md cursor-pointer transition-colors hover:text-gray-600 hover:bg-white"
  >
    <slot v-if="$slots.default"></slot>
    <template v-else>{{ name }}</template>
    <sup class="text-xs">
      <font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square" />
    </sup>
  </a>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			required: true
		},
		to: {
			type: String,
			required: true
		},
    childrenMenu: {
      type: Array,
      required: false
    }
	}
}
</script>
