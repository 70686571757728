<template>
	<router-link 
		:to="to"
		class="block px-3 py-2 text-white bg-transparent rounded-md cursor-pointer transition-colors hover:text-gray-600 hover:bg-white"
		:class="{'underline underline-offset-8 decoration-2': menuIsActive}"
  >
    <slot v-if="$slots.default"></slot>
    <template v-else>{{ name }}</template>
	</router-link>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			required: true
		},
		to: {
			type: String,
			required: true
		},
    childrenMenu: {
      type: Array,
      required: false
    }
	},
	computed: {
		menuIsActive() {
			return this.to !== '/' && this.$route.fullPath.includes(this.to)
		}
	}
}
</script>
