<template>
	<h2 class="mb-5 text-2xl font-bold text-gray-500" v-html="article.title"></h2>

	<div class="flex justify-end">
		<div class="flex flex-col">
			<p class="relative flex items-center justify-end mb-2 text-xl font-bold text-right" :style="[textStyle]">
					<span class="block w-[12px] h-[28px] mr-2" :style="backgroundStyle"></span>
					<span>{{ opinionString }}</span>
				</p>
			<a v-if="article.meta.doi" :href="'https://doi.org/' + article.meta.doi" class="text-xs font-medium transition-all cursor-pointer text-ch-red hover:underline">{{ !article.meta.doi.includes('DOI:') ? 'DOI: ' : '' }}{{ article.meta.doi }}</a>
		</div>
	</div>

	<div v-if="article.leading_content" class="my-6">
		<p class="font-medium mb-3">Authors</p>
		<p class="text-xs font-medium text-gray-600 prose-base" v-html="article.leading_content"></p>
	</div>

	<div v-if="article.summary" class="my-6">
		<p class="font-medium mb-3">Summary</p>
		<p class="text-sm leading-6 prose-base" v-html="article.summary"></p>
	</div>

  <div v-if="article.keywords_en" class="my-6">
    <p class="font-medium mb-3">Keywords</p>
    <p class="text-xs font-medium text-gray-600">{{ article.keywords_en }}</p>
  </div>

  <div v-if="article.keywords_hu" class="my-6">
    <p class="font-medium mb-3">Kulcsszavak</p>
    <p class="text-xs font-medium text-gray-600">{{ article.keywords_hu }}</p>
  </div>

	<p id="issue" class="my-6 text-lg font-medium">ISSUE: CARDIOLOGIA HUNGARICA | {{ article.meta.year }} | VOLUME {{ article.meta.volume }}, ISSUE {{ article.meta.issue }}</p>

	<div class="flex flex-col flex-wrap justify-between gap-5 mb-6 sm:flex-row">
		<button v-if="!expanded" @click="expand" class="rounded-md transition-all bg-ch-red hover:bg-ch-dark-red-100 text-white py-1.5 px-5 text-base w-full sm:w-fit">
			Read the article
		</button>
		
		<div class="flex flex-wrap gap-3 gap-x-4">
      <a v-if="article.meta.pdf" :href="article.meta.pdf" target="_blank" class="text-center sm:text-left rounded-md transition-all border-2 border-ch-red hover:text-white hover:bg-ch-red text-ch-red py-1.5 px-5 text-sm w-full sm:w-fit">
        <font-awesome-icon class="mr-2" icon="fa-solid fa-file-pdf" />
        Download the PDF
        <sup class="ml-1"><font-awesome-icon style="font-size: .6rem" icon="fa-solid fa-arrow-up-right-from-square" /></sup>
      </a>
			<button type="button" v-if="article.meta.video" @click="() => openPlayer(article.meta.video)" class="text-center sm:text-left rounded-md transition-all border-2 border-ch-red hover:text-white hover:bg-ch-red text-ch-red py-1.5 px-5 text-sm w-full sm:w-fit">
				<font-awesome-icon class="mr-2" icon="fa-solid fa-video" />
				Watch the video summary
			</button>
			<button type="button" v-if="article.meta.live" @click="() => openPlayer(article.meta.live)" class="text-center sm:text-left rounded-md transition-all border-2 border-ch-red hover:text-white hover:bg-ch-red text-ch-red py-1.5 px-5 text-sm w-full sm:w-fit">
				<font-awesome-icon class="mr-2" icon="fa-solid fa-tower-broadcast" />
				Watch CH Live interview
      </button>
		</div>
	</div>

  <div
      v-if="playerIframeSrc"
      class="flex justify-center mb-6"
      ref="playerContainerRef"
  >
    <div class="w-full sm:w-2/3">
      <div :class="!playerContainerIsIntersecting && 'fixed bottom-28 right-10 z-1 w-[240px] h-auto'">
        <div
            v-if="!playerContainerIsIntersecting"
            class="flex justify-end"
        >
          <button
              type="button"
              class="flex items-center justify-center w-11 p-1 bg-ch-dark-red-100 hover:bg-ch-dark-red-200 transition-all rounded-t-xl shadow-sm text-white text-xl"
              @click="closePlayer"
          >
            <font-awesome-icon icon="fa-solid fa-close" />
          </button>
        </div>

        <div class="relative w-full overflow-hidden pt-[56.25%]">
          <iframe
              class="absolute top-0 left-0 bottom-0 right-0 w-full h-full border-0"
              :src="playerIframeSrc"
              allowfullscreen
          />
        </div>
      </div>

      <div
          v-if="!playerContainerIsIntersecting"
          class="relative w-full overflow-hidden pt-[56.25%]"
      >
        <div class="absolute top-0 left-0 bottom-0 right-0 w-full h-full"></div>
      </div>
    </div>
  </div>

	<transition name="article-expanded">
		<div class="break-words article-inside" v-if="expanded">
			<div class="prose-base prose-a:text-ch-red prose-ul:list-disc prose-ol:list-decimal prose-table:block prose-table:w-full prose-table:overflow-x-auto prose-th:border prose-th:p-2 prose-td:border prose-td:p-2" v-html="article.content"></div>

			<div v-if="article.tailing_content" class="mt-6 prose-base prose-hr:hidden prose-a:text-ch-dark-red-100">
				<p class="mb-2"><b class="font-medium">References</b></p>
				<p class="prose-sm prose-a:text-ch-red prose-ul:list-disc prose-ol:list-decimal prose-table:block prose-table:w-full prose-table:overflow-x-auto prose-th:border prose-th:p-2 prose-td:border prose-td:p-2" v-html="article.tailing_content"></p>
			</div>
		</div>
	</transition>
</template>

<script>
import { updateTitle } from '@/composables/update-title'
import { nextTick } from 'vue'

export default {
	props: ['article'],
	data() {
		return {
			expanded: true,
      playerIframeSrc: null,
      playerObserver: null,
      playerContainerIsIntersecting: false
		}
	},
	computed: {
		opinionString() {
			return this.article?.category?.name
		},
		textStyle() {
			return {
				color: this.article?.category?.meta?.color ?? '#000000'
			}
		},
		backgroundStyle() {
			return {
				backgroundColor: this.article?.category?.meta?.color ?? '#000000'
			}
		}
	},
	methods: {
		expand() {
			this.expanded = true

			history.pushState(
				{}, null, this.$route.path + '?article=open'
			)
		},
    async openPlayer(src) {
      this.playerIframeSrc = src

      await nextTick();

      this.playerObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.playerContainerIsIntersecting = true
          } else {
            this.playerContainerIsIntersecting = false
          }
        })
      })

      if (this.$refs.playerContainerRef) {
        this.playerObserver.observe(this.$refs.playerContainerRef)
      }
    },
    closePlayer() {
      this.playerIframeSrc = null

      if (this.playerObserver) {
        this.playerObserver.disconnect()
      }
    }
	},
	created() {
		if (this.$route.query?.article === 'closed') {
			this.expanded = false
		}

		updateTitle(this.article.title)
	},
  mounted() {
    if (this.$route.query?.scroll === 'issue') {
      document.getElementById('issue').scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  },
  unmounted() {
    if (this.playerObserver) {
      this.playerObserver.disconnect()
    }
  }
}
</script>

<style scoped>
.article-expanded-enter-active,
.article-expanded-leave-active {
  transition: all 500ms ease-out;
}

.article-expanded-enter-to {
  opacity: 1;
}

.article-expanded-enter-from {
  opacity: 0
}

.article-expanded-leave-to {
  opacity: 0;
}

.article-expanded-leave-from {
  opacity: 1;
}
</style>
