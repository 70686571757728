<template>
	<a @click="close()" v-if="nav.type === 'external-menu-item'" :href="nav.href" target="_blank" class="menu-item">{{ nav.name }}<sup class="ml-1"><font-awesome-icon class="text-xs" icon="fa-solid fa-arrow-up-right-from-square" /></sup></a>
	<li @click="click(nav)" v-else-if="nav.clickable" class="menu-item">
		<RouterLink @click="close()" :to="nav.href ?? '/'">{{ nav.name }}</RouterLink>
		<ChevronDownIcon v-if="nav.type == 'dropdown-menu-item' || nav.type == 'recursive-dropdown-menu'" class="w-4 h-4 ml-auto" />
	</li>
	<li @click="click(nav)" v-else class="menu-item">
		{{ nav.name }}
		<ChevronDownIcon v-if="nav.type == 'dropdown-menu-item' || nav.type == 'recursive-dropdown-menu'" class="w-4 h-4 ml-auto" />
	</li>
</template>

<script setup>
import { ChevronDownIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
	nav: {
		type: Object,
		required: true
	},
})

const emit = defineEmits(['click', 'close'])
const click = (nav) => {
	emit('click', nav)
}
const close = () => {
	emit('close')
}
</script>

<style scoped>
.menu-item {
  @apply flex items-center px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg cursor-pointer hover:bg-gray-50;
}
</style>
