export default {
    guiOptions: {
        consentModal: {
            layout: "cloud",
            position: "bottom center",
        },
        preferencesModal: {
            layout: "box",
        },
    },
    categories: {
        necessary: {
            readOnly: true,
            enabled: true,
        },
        analytics: {},
    },
    language: {
        default: "en",
        translations: {
            en: {
                consentModal: {
                    description:
                        'This website uses cookies to improve our services and your experience, as well as to ensure a safe browsing experience. If you do not accept the optional cookies below, your website experience may be affected. <br> For more information - including how we use cookies and how to set them - please read our <a href="/cookie-policy" class="cc__link">Cookie Policy.</a>',
                    acceptAllBtn: "Accept all cookies",
                    acceptNecessaryBtn:
                        "Accept only essential cookies",
                    showPreferencesBtn: "Customize cookies",
                    closeIconLabel: "",
                },
                preferencesModal: {
                    title: "Please choose from the following cookies:",
                    acceptAllBtn: "Accept all cookies",
                    acceptNecessaryBtn:
                        "Accept only essential cookies",
                    savePreferencesBtn: "Save and apply",
                    sections: [
                        {
                            description:
                                'You can withdraw your consent at any time on the Cookie Policy page, which can be found at the bottom of the website. Withdrawing your consent does not affect the lawfulness of the processing carried out on the basis of your prior consent. <br> For further information - including on the use of cookies and how to set cookies - please read the <a href="/cookie-policy" class="cc__link">Cookie Policy.</a>',
                        },
                        {
                            title: "Strictly necessary cookies",
                            description:
                                "These cookies are absolutely necessary to use properly the functions of our website.",
                            linkedCategory: "necessary",
                            cookieTable: {
                                headers: {
                                    name: "Name",
                                    domain: "Domain",
                                    expire: "Expiry",
                                    type: "Type",
                                    desc: "Description",
                                    collect: "What type of data collected",
                                    noneu: "Data transfer to third country",
                                },
                                body: [
                                    {
                                        name: "cardiologia_hungarica_session",
                                        domain: "cardiologia.hungarica.eu",
                                        expire: "1 day",
                                        type: "HTTP cookie",
                                        desc: "This cookie created to identify user sessions. The site will not function properly without it.",
                                        collect:
                                            "This cookie does not collect or store any personal data about the website visitors.",
                                        noneu: "no",
                                    },
                                    {
                                        name: "XSRF-Token",
                                        domain: "cardiologia.hungarica.eu",
                                        expire: "1 day",
                                        type: "HTTP cookie",
                                        desc: "The application registers a unique identifier (CSRF 'token') for each active user, which can be used to verify that the authenticated user is the one who sent the request to the application.",
                                        collect:
                                            "This cookie does not collect or store any personal data about the website visitors.",
                                        noneu: "no",
                                    },
                                    {
                                        name: "cc_cookie",
                                        domain: "cardiologia.hungarica.eu",
                                        expire: "180 days",
                                        type: "HTTP cookie",
                                        desc: "This cookie is used by cookieconsent to store the cookie preferences of the users and use them when they return to the website. This cookie does not collect or store any personal data about the website visitors.",
                                        collect:
                                            "This cookie does not collect or store any personal data about the website visitors.",
                                        noneu: "no",
                                    },
                                ],
                            },
                        },
                        {
                            title: "Analytical cookies",
                            linkedCategory: "analytics",
                            cookieTable: {
                                headers: {
                                    name: "Name",
                                    domain: "Domain",
                                    expire: "Expiry",
                                    type: "Type",
                                    desc: "Description",
                                    collect: "What type of data collected",
                                    noneu: "Data transfer to third country",
                                },
                                body: [
                                    {
                                        name: "_ga",
                                        domain: "cardiologia.hungarica.eu",
                                        expire: "2 years",
                                        type: "HTTP cookie",
                                        desc: "This cookie created to collect traffic data. We use these cookies to collect information about how visitors use the website. We use the information to compile reports and improve the website.",
                                        collect:
                                            "Collects data about website visits and user behavior.",
                                        noneu: "yes",
                                    },
                                    {
                                        name: "_ga_(container-id)",
                                        domain: "cardiologia.hungarica.eu",
                                        expire: "2 years",
                                        type: "HTTP cookie",
                                        desc: "Used to maintain session state.",
                                        collect:
                                            "Collects data about website visits and user behavior.",
                                        noneu: "yes",
                                    },
                                ],
                            },
                        },
                    ],
                },
            },
        },
    },
}
