<template>
	<footer class="w-full bg-background-gray pt-10">
		<div class="bg-ch-footer-red py-8">
			<div class="container mx-auto px-2 py-6 bg-white rounded-lg">
        <div class="flex items-center justify-center gap-6 mb-6">
          <div class="max-w-20">
            <img src="@/assets/img/ch_footer_logo.png" alt="Cardiologica Hungarica">
          </div>

          <div class="max-w-32">
            <img src="@/assets/img/promenade_medical_communications.png" alt="Promenade Medical Communications">
          </div>
        </div>

        <div class="mb-6">
          <div class="flex flex-col sm:flex-row items-center justify-center gap-4">
            <RouterLink class="font-medium text-ch-dark-red-100 hover:underline" to="/instructions-for-authors/contact-the-editoral-office">Contact</RouterLink>
            <div class="hidden sm:block w-0.5 h-5 mx-3 bg-background-gray"></div>
            <RouterLink class="font-medium text-ch-dark-red-100 hover:underline" to="/cookie-policy">Cookie Policy</RouterLink>
            <div class="hidden sm:block w-0.5 h-5 mx-3 bg-background-gray"></div>
            <RouterLink class="font-medium text-ch-dark-red-100 hover:underline" to="/privacy-policy">Privacy Policy</RouterLink>
          </div>
        </div>

				<p class="font-light text-xs sm:text-sm text-center mb-1">Copyright &copy; 2017-{{ currentYear }} Promenade Publishing House. All rights reserved.</p>
				<p class="font-light text-xs sm:text-sm text-center">The content on this site is intended for health professionals.</p>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	computed: {
		currentYear() {
			return new Date().getFullYear()
		}
	}
}
</script>
