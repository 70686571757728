<template>
	<the-header></the-header>
	<main class="pt-4 bg-background-gray">
		<router-view v-slot="{ Component }">
			<transition name="router" mode="out-in">
				<component :key="new Date().toString()" :is="Component"></component>
			</transition>
		</router-view>
	</main>
	<back-to-top-button></back-to-top-button>
	<the-footer></the-footer>
	<lightbox-component></lightbox-component>
</template>

<script>
import { computed } from 'vue'
import TheHeader from '@/components/layout/header/TheHeader.vue'
import TheFooter from '@/components/layout/TheFooter.vue'
import BackToTopButton from '@/components/BackToTopButton.vue'
import LightboxComponent from '@/components/layout/LightboxComponent.vue'

import { useState } from 'vue-gtag-next'
import cookieConsent from '@/config/cookieConsent'

export default {
	components: { TheHeader, TheFooter, BackToTopButton, LightboxComponent },
	data() {
		return {
      openSearchResultsInNewTab: true
		}
	},
	provide() {
		return {
			api: this.api,
      openSearchResultsInNewTab: computed(() => this.openSearchResultsInNewTab),
      toggleOpenSearchResultsInNewTab: this.toggleOpenSearchResultsInNewTab
    }
	},
	methods: {
		api({url, method = 'GET', body = null, headers = null, callback, errorCallback = () => {}}) {
			const options = {
				method: method,
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					...headers
				},
				body: body ? JSON.stringify(body) : undefined
			}

			fetch(`${this.$apiPath}${url}`, options)
				.then(response => {
					if (!response.ok) throw new Error(response.statusText)

					return response.json()
				})
				.then(callback)
				.catch(errorCallback)
		},
    toggleOpenSearchResultsInNewTab() {
      this.openSearchResultsInNewTab = !this.openSearchResultsInNewTab
    }
	},
	created() {
		this.api({
			url: '/meta/issue_titles',
			callback: data => {
				this.$store.commit('setIssueTitles', data)
			}
		})
	},
  mounted() {
    cookieConsent.onConsent = () => {
      if (this.$CookieConsent.acceptedCategory("analytics")) {
        const { property } = useState()

        property.value = {
          id: import.meta.env.VITE_GA_MEASUREMENT_ID
        }
      }
    }

    cookieConsent.onChange = ({ changedCategories }) => {
      if (changedCategories.includes("analytics")) {
        if (this.$CookieConsent.acceptedCategory("analytics")) {
          const { property } = useState()

          property.value = {
            id: import.meta.env.VITE_GA_MEASUREMENT_ID
          }
        }
      }
    }

    this.$CookieConsent.run(cookieConsent)
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
	@apply font-ubuntu;
}

/* Make the main content fill available vertical space */
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#app > main {
  flex-grow: 1;
}

/* CookieConsent CSS Override */
#cc-main .pm.pm--box {
  max-width: 54em;
}
</style>

<style scoped>
.router-enter-active,
.router-leave-active {
  transition: all 200ms ease-out;
}

.router-enter-to {
  opacity: 1;
}

.router-enter-from {
  opacity: 0
}

.router-leave-to {
  opacity: 0;
}

.router-leave-from {
  opacity: 1;
}
</style>
