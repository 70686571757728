<template>
	<div>
    <router-link
        :to="issueLink"
        :target="openNewTab ? '_blank' : undefined"
    >
      <h2 class="mb-5 text-xl font-bold text-gray-500 hover:underline">{{ issue?.title }}</h2>
    </router-link>
		<div class="flex flex-wrap items-center justify-between gap-5 mb-5">
      <div class="flex-1 flex flex-wrap items-center gap-2">
        <router-link v-if="issue?.meta?.pdf" :to="issueLink + '?scroll=issue'" class="flex items-center gap-1.5 px-2.5 py-1 text-xs text-gray-800 font-medium bg-gray-100 rounded-full">
          <font-awesome-icon icon="fa-solid fa-file-pdf" />
          <div>PDF</div>
        </router-link>

        <router-link v-if="issue?.meta?.video" :to="issueLink + '?scroll=issue'" class="flex items-center gap-1.5 px-2.5 py-1 text-xs text-gray-800 font-medium bg-gray-100 rounded-full">
          <font-awesome-icon icon="fa-solid fa-video" />
          <div>Video Summary</div>
        </router-link>

        <router-link v-if="issue?.meta?.live" :to="issueLink + '?scroll=issue'" class="flex items-center gap-1.5 px-2.5 py-1 text-xs text-gray-800 font-medium bg-gray-100 rounded-full">
          <font-awesome-icon icon="fa-solid fa-broadcast-tower" />
          <div>CH Live Interview</div>
        </router-link>
      </div>

			<div class="flex-1 flex flex-col">
        <p class="mb-2 text-xl font-bold opinion" :style="[textStyle]">
          <span class="block w-[12px] h-[28px] mr-2" :style="backgroundStyle"></span>
          <span>{{ opinionString }}</span>
        </p>
        <a v-if="issue?.meta?.doi" :href="'https://doi.org/' + issue?.meta?.doi" class="inline-flex justify-end text-xs font-medium transition-all cursor-pointer text-ch-red hover:underline">{{ !issue?.meta?.doi?.includes('DOI:') ? 'DOI: ' : '' }}{{ issue?.meta?.doi }}</a>
			</div>
		</div>
		<div :class="graphicalAbstractClassList">
			<p class="py-0 text-sm font-medium text-gray-800 prose-base" v-html="issue?.leading_content"></p>
      <div class="flex-initial w-60">
			  <img @click="showSingle" v-if="issue?.meta?.graphical_abstract" :src="issue?.meta?.graphical_abstract" class="transition-all cursor-pointer graphical-abstract hover:scale-105" alt="">
      </div>
		</div>
	</div>
</template>

<script>
import { cn } from '@/utils/cn'

export default {
	props: {
    article: {
      type: Object,
      required: true
    },
    openNewTab: {
      type: Boolean,
      default: false
    }
  },
	data() {
		return {
			issue: this.article
		}
	},
	computed: {
		opinionString() {
			/* return this.$store.state.issueTitles[this.issue?.meta?.opinion] */
			return this.issue?.category?.name
		},
		textStyle() {
			return {
				color: this.issue?.category?.meta?.color ?? '#000000'
			}
		},
		backgroundStyle() {
			return {
				backgroundColor: this.issue?.category?.meta?.color ?? '#000000'
			}
		},
		graphicalAbstractClassList() {
			return cn('p-4 py-3 bg-gray-100 rounded-md', {
				'flex justify-between items-center gap-4': this.issue?.meta?.graphical_abstract
			})
		},
    issueLink() {
      return this.issue?.page_path + '/' + this.issue?.slug
    }
	},
	methods: {
		showSingle() {
			this.$store.commit('setLightboxImages', this.issue?.meta?.graphical_abstract)
			this.$store.commit('toggleLightbox', true)
		}
	
	}
}
</script>

<style scoped>
.opinion {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
</style>
