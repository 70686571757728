<template>
	<header class="w-full">
		<div class="container py-4 mx-auto bg-white">
      <div class="flex items-center justify-between gap-4">
        <RouterLink to="/" class="flex items-center gap-2 md:gap-4">
          <div class="w-28 md:w-32">
            <img src="@/assets/img/ch_header_logo.png" alt="Cardiologia Hungarica">
          </div>

          <div>
            <p class="mb-2 text-2xl font-thin text-ch-red md:text-4xl font-arial">Cardiologia<br>Hungarica</p>
            <p class="text-xs text-gray-400 md:text-sm font-arial">Scientific Journal of the Hungarian Society of Cardiology</p>
          </div>
        </RouterLink>

        <a href="https://mkardio.hu/info.aspx?web_id=E3372E0F7ACD587&sp=312" target="_blank" class="block w-28 md:w-32">
          <img src="https://cardiologia.hungarica.eu/backend/public/api/v1/media/uploads/2024/03/angol_logo_rgb-removebg-preview.png" alt="Hungarian Society of Cardiology">
        </a>
      </div>
		</div>
	</header>
	<nav class="sticky top-0 left-0 z-10 bg-[url('@/assets/img/ch_header_nav_bg.png')] bg-cover shadow-lg">
		<div class="container hidden mx-auto lg:block">
			<div class="flex items-center gap-x-0.5 py-2 text-sm xl:text-base font-medium text-white">
				<template v-if="!menu">
					<skeleton-loader class="h-6 mr-6 w-14" />
					<skeleton-loader class="w-24 h-6 mr-6" />
					<skeleton-loader class="w-20 h-6 mr-6" />
					<skeleton-loader class="w-32 h-6" />
					<skeleton-loader class="w-20 h-6 ml-auto" />
				</template>

				<template v-else>
					<component
            v-for="menuItem of menu"
            :key="menuItem.name"
            :is="menuItem.type"
            :name="menuItem.name"
            :to="menuItem.href"
            :children-menu="menuItem.children"
          />

					<search-menu-item />
				</template>
			</div>
		</div>

		<div class="container mx-auto sm:block lg:hidden">
			<template v-if="!menu">
				<div class="flex items-center justify-between py-2 mx-6 text-base font-medium text-white h-14">
					<skeleton-loader class="w-32 h-6" />
					<skeleton-loader class="w-10 h-6" />
				</div>
			</template>

			<template v-else>
				<MobileMenu :navigation="menu" />
			</template>
		</div>
	</nav>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import MenuItem from './MenuItem.vue'
import ExternalMenuItem from './ExternalMenuItem.vue'
import SearchMenuItem from './search/SearchMenuItem.vue'
import DropdownMenuItem from './DropdownMenuItem.vue'
import FullscreenMenu from './FullscreenMenu.vue'
import SkeletonLoader from '../base/SkeletonLoader.vue'
import MobileMenu from './MobileMenu.vue'

export default {
	components: { MenuItem, ExternalMenuItem, SearchMenuItem, DropdownMenuItem, SkeletonLoader, MobileMenu },
	inject: ['api'],
	data() {
		return {
			menu: null
		}
	},
	created() {
		this.api({
			url: '/layout/header',
			callback: data => {
				this.menu = data
			}
		})
	}
}
</script>
