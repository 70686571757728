import { createApp } from 'vue'

import store from './config/store'
import router from './config/routes'

import App from './App.vue'
import vueClickOutsideElement from 'vue-click-outside-element'

import BaseContainer from './components/layout/base/BaseContainer.vue'
import IssueArticle from './components/layout/article/IssueArticle.vue'
import IssueSingleArticle from '@/components/layout/article/IssueSingleArticle.vue'
import PostArticle from '@/components/layout/article/PostArticle.vue'
import ArchiveArticle from '@/components/layout/article/ArchiveArticle.vue'
import SkeletonLoader from './components/layout/base/SkeletonLoader.vue'
import BasicSkeletonLoader from './components/layout/skeleton/BasicSkeletonLoader.vue'
import ImageSkeletonLoader from './components/layout/skeleton/ImageSkeletonLoader.vue'

import CookieConsentVue from '@/plugins/CookieConsentVue'
import VueGtag, { trackRouter } from 'vue-gtag-next'

/**
 * FontAwesome core
 */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/**
 * FontAwesome icons
 */
import { faUserSecret, faMagnifyingGlass, faPlay, faChevronUp, 
	faArrowUpRightFromSquare, faNewspaper, faFilePdf, faVideo, 
	faTowerBroadcast, faSliders, faSearch, faList, faChevronRight,
	faChevronLeft, faBars, faImage, faXmark, faArrowRight, faArrowLeft,
	faTrash, faArrowRightArrowLeft, faStar, faFloppyDisk, faCopy, faAngleDown, faCheck } from '@fortawesome/free-solid-svg-icons'

library.add(faUserSecret, faMagnifyingGlass, faPlay, faChevronUp, 
	faArrowUpRightFromSquare, faNewspaper, faFilePdf, faVideo, 
	faTowerBroadcast, faSliders, faSearch, faList, faChevronRight,
	faChevronLeft, faBars, faImage, faXmark, faArrowRight, faArrowLeft,
	faTrash, faArrowRightArrowLeft, faStar, faFloppyDisk, faCopy, faAngleDown, faCheck)

/**
 * Application creation
 */
const	app = createApp(App)
		app.component('font-awesome-icon', FontAwesomeIcon)
		app.component('base-container', BaseContainer)
		app.component('issue-article', IssueArticle)
		app.component('archive-article', ArchiveArticle)
		app.component('issue-single-article', IssueSingleArticle)
		app.component('post-article', PostArticle)
		app.component('skeleton-loader', SkeletonLoader)
		app.component('basic-skeleton-loader', BasicSkeletonLoader)
		app.component('image-skeleton-loader', ImageSkeletonLoader)
		app.use(vueClickOutsideElement)
		app.use(router)
		app.use(store)
		app.use(CookieConsentVue)
		app.use(VueGtag)

trackRouter(router)

router.isReady().then(() => {
	app.mount('#app')
})


/**
 * Global variables
 */
app.config.globalProperties.$apiPath = import.meta.env.VITE_API_PATH
