<template>
	<div>
		<h1 v-if="article.title" class="text-ch-red text-2xl md:text-3xl font-medium md:leading-8 mb-0" v-html="article.title"></h1>
		<h2 v-if="article.subtitle" class="text-gray-700 text-lg md:text-2xl mt-0.5" v-html="article.subtitle"></h2>

		<div v-if="objects !== null && objects.children.length > 0" class="grid grid-cols-1 gap-8 lg:grid-cols-3 sm:grid-cols-2 mt-6">
			<template v-for="child of objects.children" :key="child.slug">
				<a v-if="child.meta?.external_link" target="_blank" :href="child.meta.external_link">
					<img class="transition-all rounded-none hover:scale-105 graphical-abstract !w-auto max-h-[340px]" :src="child?.meta?.img" alt="" />
					<p class="text-ch-red text-sm md:text-base mt-1">{{ child?.name }}</p>
          <p v-if="child?.meta?.subtitle" class="text-sm md:text-base font-light">{{ child?.meta?.subtitle }}</p>
				</a>
				<RouterLink v-else :to="`/archive/${objects.slug}/${child.slug}`">
          <img class="transition-all rounded-none hover:scale-105 graphical-abstract !w-auto max-h-[340px]" :src="child?.meta?.img" alt="" />
					<p class="text-ch-red text-sm md:text-base mt-1">{{ child?.name }}</p>
          <p v-if="child?.meta?.subtitle" class="text-sm md:text-base font-light">{{ child?.meta?.subtitle }}</p>
				</RouterLink>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	props: ['article'],
	inject: ['api'],
	data() {
		return {
			objects: null,
			notFound: false
		}
	},
	created() {
		const issueSlug = this.$route?.params?.pathMatch?.[1] || ''

		this.api({
			url: `/issue/${issueSlug}`,
			callback: data => {
				this.objects = data
			},
			errorCallback: () => {
				this.notFound = true
			}
		})
	}
}
</script>
